import React from "react"
import styled, { css } from 'styled-components'

import Layout from "../components/layout"

import SEO from "../components/seo"

const skillsList = [
  'React.js', 'Node.js', 'Express.js', 'GraphQl', 'Redux', 'Styled-components', 'Gatsby', 
  'MERN Stack','MEAN Stack', 'JAM Stack', 'Headless CMS', 'Serverless', 'Static Website', 'Progressive Web App',
  'Strapi', 'Django', 'Wagtail', 'Angular', 'Next.js', 'MySql', 'SQL', 'MongoDB', 'Netlify', 'Heroku',
  'System Dev', 'Web Dev', 'SPA Dev', 'Agile',
  'React Native', 'C#', 'Laravel', 'Wordpress', 'PHP',
  'JQuery'  
]

const Skills = ({ location }) => {

  return (
    <Layout path={location}>
      <SEO title="Skills" />
          
          <FlexContainer>
            {skillsList.map((skill, index) => 
              <GlowingBall 
                key={index}
                size="100px"
                external="red"
                internal="#f0f"
              >
                <GlowingElement>
                  {skill}
                </GlowingElement>
              </GlowingBall>
            )}
          </FlexContainer>
  
      
    </Layout>
  )
}

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 100%;
  margin: 0 auto;
`

const GlowingBall = styled.div`
  padding: 5px;
  ${props => (props.external && props.internal) && css`
    box-shadow:
      inset 0 0 50px #fff,     
      inset 20px 0 80px ${props => props.internal},   
      inset -20px 0 80px ${props => props.external},  
      inset 20px 0 300px ${props => props.internal},  
      inset -20px 0 300px ${props => props.external}, 
      0 0 50px #fff,            
      -10px 0 80px ${props => props.internal},        
      10px 0 80px ${props => props.external};         
  `};
  
  border-radius: 50%;
  width: ${props => props.size ? props.size : '100px'};
  height: ${props => props.size ? props.size : '100px'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  font-size: 1rem;

  @media only screen and (max-width: 950px) {
    width: 70px;
    height: 70px;
    font-size: .7rem;
  }

  @media only screen and (max-width: 250px) {
    width: 50px;
    height: 50px;
    font-size: .5rem;
  }
`

const GlowingElement = styled.div`

`

export default Skills
